// Stylesheets
import './main.scss';

// Imports all component based scss files.
import './components/**/*.scss';

// import favicon here.
import './resources/images/favicon.png';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordion
  }
}

(() => {
  let pageSections: NodeListOf<HTMLElement>;
  const pageSectionIds: string[] = [];
  let scrollNavAnchors: NodeListOf<HTMLAnchorElement>;
  const scrollNavHrefs: string[] = [];
  let isFooterInViewport = false;
  const activeNavClass = 'active';
  let sideNav: HTMLElement;
  let windowWidth = window.innerWidth;

  // handling button click events with the bus events being emitted
  const handleButtonClicks = () => {
    const patientInfoCta = document.querySelector(
      '#patient-safety-trigger-desk'
    ) as HTMLButtonElement;
    const patientInfoMobileCta = document.querySelector(
      '#patient-safety-nav-trigger-mobile'
    ) as HTMLButtonElement;
    const modalCloseCta = document.querySelector(
      '.page-load-modal .modal-wrapper .close'
    ) as HTMLAnchorElement;

    const brandMenuCta = document.querySelector(
      '#allergan-brand-trigger-desk'
    ) as HTMLButtonElement;
    const directionsCta = document.querySelector(
      '#direction-trigger-desk'
    ) as HTMLButtonElement;
    const directionsMobileCta = document.querySelector(
      '#direction-nav-trigger-mobile'
    ) as HTMLButtonElement;

    window.Bus.on('emu-button:click', ({ id }) => {
      const isDesktopView = window.matchMedia('(min-width: 992px)').matches;
      // closing brand menu when brand menu close button is clicked
      if (id === 'allergan-brand-menu-close') {
        brandMenuCta?.click();
      }

      // closing patient safety menu when patient menu close button is clicked
      if (id === 'patient-safety-menu-close') {
        if (isDesktopView) {
          patientInfoCta?.click();
        } else {
          patientInfoMobileCta?.click();
        }
      }

      // closing directions menu when directions menu close button is clicked
      if (id === 'direction-menu-close') {
        if (isDesktopView) {
          directionsCta?.click();
        } else {
          directionsMobileCta?.click();
        }
      }

      // closing modal when the button present inside it is clicked
      if (id === 'modal-button' && modalCloseCta) {
        modalCloseCta?.click();
      }

      // Redirecting to the jawline tab section when the modal button or the jaw inspiring section is clicked.
      if (id === 'modal-button' || id === 'jaw-inspiring-button') {
        const tabLinks = document.querySelectorAll(
          '.treatment-areas__content-tabs a'
        ) as NodeListOf<HTMLElement>;

        tabLinks.forEach(tab => {
          if (tab.getAttribute('data-emu-item-title') === 'JAWLINE') {
            tab.click();
          }
        });
      }
    });
  };

  // closes mobile menu if it is open
  const closeMobileMenu = () => {
    const isMobileView = window.matchMedia('(max-width: 991px)').matches;
    const menuTrigger = document.querySelector(
      '.header__mobile-nav-trigger'
    ) as HTMLButtonElement;
    const isMenuOpen = menuTrigger?.classList.contains('js-toggle-on');

    if (isMenuOpen && isMobileView) {
      const menuNavWrapper = document.querySelector('.header__nav-wrapper');
      menuTrigger?.click();
      menuNavWrapper?.scrollTo(0, 0);
    }
  };

  // should close mobile menu when one of the links is clicked
  // but this should not be closed when brand nav items are clicked.
  const handleMobileMenuAnchorClick = () => {
    const closeTriggerLinks = document.querySelectorAll(
      '.mobile-link-container a'
    );
    closeTriggerLinks?.forEach(el => {
      el.addEventListener('click', () => {
        closeMobileMenu();
      });
    });
  };

  // checks and returns if the image is present in the viewport
  const isElInViewport = el => {
    const elementTop = el.offsetTop;
    var elementBottom = elementTop + el.clientHeight;

    var viewportTop = window.scrollY;
    var viewportBottom = viewportTop + window.innerHeight;

    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  // when the page is scrolled
  const handleScrollNavHighlight = () => {
    window.addEventListener('scroll', () => {
      let activeId = '';
      pageSections.forEach(section => {
        if (isElInViewport(section)) {
          activeId = section.getAttribute('id') || '';
        }
      });

      // according to the live site, if scroll position is less than 500, remove active class from the link
      if (activeId === 'juvederm-collection') {
        if (window.scrollY < 500) {
          activeId = pageSectionIds[0];
        }
      }

      if (activeId) {
        scrollNavHrefs.forEach((href, i) => {
          if (activeId === href) {
            scrollNavAnchors[i]?.classList.add(activeNavClass);
          } else {
            scrollNavAnchors[i]?.classList.remove(activeNavClass);
          }
        });
      } else {
        scrollNavAnchors.forEach(el => {
          el.classList.remove(activeNavClass);
        });
      }
    });
  };

  // adding variables that are used across the file
  const initVariables = () => {
    pageSections = document.querySelectorAll(
      '.page-section'
    )! as NodeListOf<HTMLElement>;
    if (pageSections?.length) {
      pageSections.forEach(section => {
        const id: string = (<HTMLElement>section)?.getAttribute('id') || '';
        pageSectionIds.push(id);
      });
    }

    scrollNavAnchors = document.querySelectorAll(
      '.header__main-menu a'
    )! as NodeListOf<HTMLAnchorElement>;
    if (scrollNavAnchors?.length) {
      scrollNavAnchors.forEach(el => {
        const href = el.getAttribute('href')?.split('#')?.[1] || '';
        scrollNavHrefs.push(href);
      });
    }

    sideNav = document.querySelector('.header__main-menu')!;
  };

  const handlePageUrlWithHash = () => {
    const pageHash = window.location.hash;
    if (pageHash) {
      const el = document.querySelector(pageHash);
      if (el) {
        window.addEventListener('load', () => {
          el.scrollIntoView();
          history.replaceState(
            '',
            document.title,
            window.location.pathname + window.location.search
          );
        });
      }
    }
  };

  // checks and updates the isFooterInViewport flag when footer is visible on the screen.
  // used to handle side nav position and to implement ISI under footer
  const checkFooterInViewport = () => {
    const observerFooter = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        isFooterInViewport = entry.isIntersecting;
      });
    });

    observerFooter.observe(
      document.querySelector('.cmp-experiencefragment--footer') as HTMLElement
    );
  };

  const handleFooterInViewport = () => {
    let lastScrollTop = window.scrollY;

    const fitSideNav = () => {
      const curScrollTop = window.scrollY;

      if (isFooterInViewport) {
        const topVal = window.getComputedStyle(sideNav).top;
        document.body.classList.add('footer-in-viewport');
        const diff = curScrollTop - lastScrollTop;
        sideNav.style.top = `min(100%, calc(${topVal} - ${diff}px))`;
      } else {
        document.body.classList.remove('footer-in-viewport');
        sideNav.style.top = '100%';
      }

      lastScrollTop = curScrollTop;
    };

    window.addEventListener('scroll', fitSideNav);

    window.addEventListener('resize', () => {
      lastScrollTop = window.scrollY;
      fitSideNav();
    });
  };

  // by default, AAAEM Common ISI does not allow two buttons in ISI.
  // Adding a button, so that the functionality to scroll to ISI can be achieved.
  const appendIsiButton = () => {
    const readMoreTextEl = document.querySelector(
      '.isi-read-more-text'
    ) as HTMLElement;
    const readMoreText = readMoreTextEl?.innerText;
    const btnWrapper = document.querySelector(
      '.aaaem-isi-container__button-wrapper'
    ) as HTMLElement;
    if (readMoreText && btnWrapper) {
      const readMoreAnchor = document.createElement('a');
      readMoreAnchor.textContent = readMoreText;
      readMoreAnchor.setAttribute('href', '#isi');
      readMoreAnchor.classList.add(
        'cmp-button',
        'aaaem-button',
        'read-more-button'
      );

      btnWrapper.appendChild(readMoreAnchor);
    }
  };

  // the back to top should start to show at a certain point
  // AAAEM-Common has this functionality, but that does not match the requirement. The threshold points does not match the requirement
  const handleBackToTop = () => {
    const backToTopBtn = document.querySelector('.aaaem-back-to-top-button');
    if (backToTopBtn) {
      const showClass = 'show';
      window.addEventListener('scroll', () => {
        if (windowWidth > 769) {
          if (window.scrollY > 1200) {
            backToTopBtn.classList.add(showClass);
          } else {
            backToTopBtn.classList.remove(showClass);
          }
        } else if (windowWidth <= 768) {
          if (window.scrollY > 650) {
            backToTopBtn.classList.add(showClass);
          } else {
            backToTopBtn.classList.remove(showClass);
          }
        }
      });
    }
  };

  // when any anchor on the page is clicked, if there is an element on the page with that specific href as id, scroll the page to that section
  const handlePageAnchors = () => {
    // selecting all anchors that has hash in hrefs. But avoiding tabs
    const allAnchors = document.querySelectorAll(
      'a[href*="#"]:not([role="tab"])'
    ) as NodeListOf<HTMLAnchorElement>;
    const isiEl = document.querySelector(
      '.cmp-experiencefragment--isi'
    ) as HTMLElement;
    const patientInfoCta = document.querySelector(
      '#patient-safety-trigger-desk'
    ) as HTMLButtonElement;
    const patientInfoMobileCta = document.querySelector(
      '#patient-safety-nav-trigger-mobile'
    ) as HTMLButtonElement;

    const directionsCta = document.querySelector(
      '#direction-trigger-desk'
    ) as HTMLButtonElement;
    const directionsMobileCta = document.querySelector(
      '#direction-nav-trigger-mobile'
    ) as HTMLButtonElement;
    const isDesktopView = window.matchMedia('(min-width: 992px)').matches;

    allAnchors.forEach(el => {
      const href = el.getAttribute('href') || '';
      const hash = href?.split('#')[1];

      if (hash) {
        let section = document.querySelector(`#${hash}`);

        if (hash === 'isi' && isiEl) {
          section = isiEl;
        }

        if (section) {
          el.addEventListener('click', e => {
            e.preventDefault();
            section?.scrollIntoView?.();
          });
        } else if (hash === 'patient-safety-info') {
          el.addEventListener('click', e => {
            e.preventDefault();
            if (isDesktopView) {
              // Checks if the direction drop down is already opened and triggers the direction cta button to close it.
              if (directionsCta.classList.contains('js-toggle-on')) {
                directionsCta.click();
              }

              // Checks if the patient info drop down is already closed and triggers the patient cta button to open it.
              if (!patientInfoCta.classList.contains('js-toggle-on')) {
                patientInfoCta?.click();
              }
            } else {
              patientInfoMobileCta?.click();
            }
          });
        } else if (hash === 'directions-menu') {
          el.addEventListener('click', e => {
            e.preventDefault();
            if (isDesktopView) {
              // Checks if the patient info drop down is already opened and triggers the patient cta button to close it.
              if (patientInfoCta.classList.contains('js-toggle-on')) {
                patientInfoCta.click();
              }

              // Checks if the direction drop down is already closed and triggers the direction cta button to open it.
              if (!directionsCta.classList.contains('js-toggle-on')) {
                directionsCta?.click();
              }
            } else {
              directionsMobileCta?.click();
            }
          });
        }
      }
    });
  };

  // when stars section in the syringe design is in viewport, add an animation to it
  const animateStars = () => {
    const starWrapper = document.querySelector('#syringe-stars') as HTMLElement;
    const stars = starWrapper?.querySelectorAll(
      'li'
    ) as NodeListOf<HTMLLIElement>;
    if (starWrapper && stars?.length) {
      const addClassToStars = () => {
        stars.forEach(el => {
          el.classList.add('animate-star');
        });
      };

      if (starWrapper.classList.contains('js-emu-inview')) {
        addClassToStars();
      } else {
        window.Bus.on('emu-container:inview', ({ id }) => {
          if (id === 'syringe-stars') {
            addClassToStars();
          }
        });
      }
    }
  };

  // the rating in the syringe design needs to be animated from 0 to 100 when it comes into the view
  const animateRating = () => {
    const textWrapper = document.querySelector(
      '#syringe-design-rating'
    ) as HTMLElement;
    const text = textWrapper?.querySelector('.emphasis') as HTMLSpanElement;
    if (textWrapper && text) {
      const animateText = () => {
        let progressValue = 0;
        let progressEndValue = 100;
        let progressSpeed = 50;
        let progress = setInterval(() => {
          if (progressValue < 100) progressValue++;

          text.textContent = `${progressValue}`;
          textWrapper.style.background = `conic-gradient(#3D1152 ${
            progressValue * 3.6
          }deg,#fff ${progressValue * 3.6}deg)`;

          if (progressValue >= progressEndValue) {
            clearInterval(progress);
          }
        }, progressSpeed);
      };

      if (textWrapper.classList.contains('js-emu-inview')) {
        animateText();
      } else {
        window.Bus.on('emu-text:inview', ({ id }) => {
          if (id === 'syringe-design-rating') {
            animateText();
          }
        });
      }
    }
  };

  // treatment areas has image map, clicking on which should change the contents of the page
  const handleTreatmentAreas = () => {
    const tabsComponentTriggers = document.querySelectorAll(
      '#treatment-areas-content-tabs > .aaaem-tabs__tablist a'
    ) as NodeListOf<HTMLAnchorElement>;
    let mapAreaButtons = document.querySelectorAll(
      '.treatment-areas__img-map-img .emu-mapping-marker'
    ) as NodeListOf<HTMLButtonElement>;
    const mapTabInstance = window._tabbyInstances?.['treatment-areas-map-tabs'];
    const mapTabTriggers = document.querySelectorAll(
      '#treatment-areas-map-tabs .aaaem-tabs__tablist a'
    ) as NodeListOf<HTMLAnchorElement>;

    // for a given title, sets the corresponding map area items as active
    const setMapAreaActiveBtns = title => {
      mapAreaButtons = mapAreaButtons?.length
        ? mapAreaButtons
        : (document.querySelectorAll(
            '.treatment-areas__img-map-img .emu-mapping-marker'
          ) as NodeListOf<HTMLButtonElement>);
      if (mapAreaButtons?.length) {
        mapAreaButtons.forEach(btn => {
          const btnTitle = btn.getAttribute('aria-label');
          if (btnTitle === title) {
            btn.classList.add('active');
          } else {
            btn.classList.remove('active');
          }
        });
      }
    };

    // for a given title, sets the mini tab components active tab
    const setMapAreaActiveTab = title => {
      if (mapTabInstance) {
        mapTabTriggers.forEach(tabTrigger => {
          const curTriggerTitle = tabTrigger.getAttribute(
            'data-emu-item-title'
          );
          if (curTriggerTitle === title) {
            mapTabInstance.toggle(tabTrigger);
          }
        });
      }
    };

    // when main tab trigger is clicked, changing the mini tab component's active tab and adding active class to image map markers
    if (tabsComponentTriggers?.length) {
      tabsComponentTriggers.forEach(el => {
        el.addEventListener('click', () => {
          const title = el.getAttribute('data-emu-item-title');
          if (title) {
            setMapAreaActiveBtns(title);
            setMapAreaActiveTab(title);
          }
        });
      });

      // whenever map area is clicked, clicking the corresponding main tab trigger
      window.Bus.on('emu-image-map:areaClick', ({ title, id }) => {
        if (
          id.includes('treatment-area-mob-img') ||
          id.includes('treatment-area-desk-img')
        ) {
          tabsComponentTriggers.forEach(el => {
            const tabTriggerTitle = el.getAttribute('data-emu-item-title');
            if (tabTriggerTitle === title) {
              el.click?.();
            }
          });
        }
      });

      // on page load, making sure the appropriate tab and the marker is selected
      window.addEventListener('load', () => {
        tabsComponentTriggers.forEach(el => {
          const isSelected = el.getAttribute('aria-selected');
          if (isSelected === 'true') {
            el.click?.();
          }
        });
      });

      // setting the first tab active
      tabsComponentTriggers[0]?.click?.();
    }
  };

  const init = () => {
    initVariables();
    handleMobileMenuAnchorClick();
    appendIsiButton();
    handlePageUrlWithHash();
    checkFooterInViewport();
    handleFooterInViewport();
    handleBackToTop();

    // if there are page sections present on the page, handle the navigation highlighting
    if (pageSectionIds.length) {
      handleScrollNavHighlight();
    }

    if (window.Bus) {
      handleButtonClicks();
      animateStars();
      animateRating();
      handleTreatmentAreas();
    }

    handlePageAnchors();

    windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      windowWidth = window.innerWidth;
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
